<template>
    <div class="px-5 pb-2">
<!--Client Heading-->
        <div class="d-flex align-center py-0">
            <div class="flex-shrink-1 flex-grow-0">
                <v-icon medium left color="secondary lighten-1">mdi-domain</v-icon>
            </div>

            <div class="flex-grow-1 title secondary--text d-flex align-center">
                {{ client.accountName }}
                <a style="text-decoration: none; cursor: pointer" :href="comServer.host" target="_blank" class="pl-2 body-1 secondary--text">[{{comServer}}]</a>
            </div>

            <div class="flex-shrink-1 flex-grow-0">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                small
                                color="primary"
                                text
                                icon
                                v-on="on"
                                @click="sendSummaryReport()"
                                ref="sendBtn"
                        >
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </template>
                    Resend Summary Report
                </v-tooltip>
            </div>
        </div>
<!--Client Detail-->
        <div v-for="(device) in clientDevices">
            <div class="pl-3 py-2" v-if="filterItems === null || typeof filterItems[device.id] !== 'undefined'">
                <client-list-device
                    :commet-server-id="client.cometServerID"
                    :filter-items="filterItems == null ? null : filterItems[device.id]"
                    :device="device"
                    :client-i-d="client.id"
                    @dayClicked="$emit('dayClicked', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script>

import ClientListDevice from "@/components/ClientsList/ClientListDevice";
import {mapGetters} from 'vuex'
import {newReq} from '@/core/request.js';
import {showSnack} from "@/core/codeFunctions";

export default {
    components: {ClientListDevice},
    data() {
        return {
            droppedClients: [],
        }
    },
    props: {
        client: Object,
        filterItems: Object,
    },
    methods: {
        sendSummaryReport() {
            newReq('post', `dash/sendReport/${this.client.id}`)
                    .then(() => {
                        showSnack('Report Sent');
                        this.$refs.sendBtn.disabled = true
                    })
                    .catch(e => {
                        console.log(e)
                    })
        }
    },
    computed: {
        ...mapGetters(['cometServersRef']),
        clientDevices() {
            return Array.isArray(this.client.devices)
                ? [...this.client.devices].sort((a, b) => a.description > b.description ? 1 : -1)
                : []
        },

        comServer() {
            return this.cometServersRef[this.client.cometServerID].description
        }

    }
}
</script>

<style scoped>

</style>