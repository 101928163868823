<template>
    <div>
        <v-hover>
            <template #default="{hover}">
                <div class="d-flex align-center flex-wrap">
                    <v-icon medium left>{{sourceIcon}}</v-icon>
                    <!--Source Heading-->
                    <v-tooltip top
                               :disabled="retryEnabled"
                    >
                        <template #activator="{on}">
                            <div class="flex-grow-1 body-2 d-flex align-center" :class="!retryEnabled ? 'error--text font-weight-bold' : null" v-on="on">
                                {{source.description}}
                                <v-icon
                                    v-if="!retryEnabled"
                                    right
                                    small
                                    :color="retryEnabled ? null : 'error'"
                                >
                                    mdi-skip-next
                                </v-icon>

                                <v-btn v-if="hover || startingBackupNow" small color="deep-orange" class="mx-2" :loading="startingBackupNow" :disabled="startingBackupNow" @click="initBackupNow">Backup Now</v-btn>

                            </div>
                        </template>
                        Retry if last job missed on boot {{retryEnabled ? 'ENABLED' : 'DISABLED'}}
                    </v-tooltip>



                    <!--Source Status-->
                    <div class="flex-shrink-1 d-flex align-center flex-wrap justify-sm-end justify-xs-end justify-md-end">
                        <div
                            class="circle ma-1 d-flex align-center justify-center caption font-weight-bold"
                            :class="day.color"
                            v-for="day in daysRef"
                            @click="day.jobsTotal > 0 ? $emit('dayClicked', {event: $event, data: getRefData(day.key)}) : null"
                        >
                            {{ day.jobsTotal === 0 ? '' : day.jobsTotal }}
                        </div>
                    </div>
                </div>
            </template>
        </v-hover>

        <simple-dlg
            v-if="showBackupNowWindow"
            title="Backup Now"
            v-model="showBackupNowWindow"
        >
            <template #body>
                <v-card-text>
                    Run backup on <strong>{{source.description}}</strong> skip if already running ENABLED by default
                    <br>Please select Vault

                    <v-autocomplete
                        v-model="selectedVaultID"
                        label="Vault"
                        :items="vaults"
                        item-text="description"
                        item-value="id"
                        outlined
                        dense
                        class="pt-3"
                        hide-details
                        :disabled="startingBackup || error || !targetId"
                    ></v-autocomplete>
                </v-card-text>
            </template>

            <template #action>
                <div class="d-flex justify-end">
                    <v-btn
                        :disabled="startingBackup || error || !selectedVaultID || !targetId"
                        :loading="startingBackup"
                        color="deep-orange"
                        class="mx-2 mb-2"
                        @click="backupNow"
                    >Backup Now
                    </v-btn>
                </div>
            </template>
        </simple-dlg>

    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {showSnack} from "@/core/codeFunctions";
import {newReq} from "@/core/request";
import SimpleDlg from "@/components/General/SimpleDlg.vue";


export default {
    components: {SimpleDlg},
    props: {
        source: Object,
        deviceID: String,
        srcRef: Object,
        clientID: String,
    },
    data() {
        return {
            startingBackupNow: false,
            showBackupNowWindow: false,
            targetIds: [],
            vaults: [],
            selectedVaultID: null,
            targetId: null,
            error: false,
            startingBackup: false
        }
    },
    methods: {

        async backupNow() {
            try {
                this.startingBackup = true;
                const client = this.clients.find(client => client.id === this.clientID)
                const data = await newReq('POST', `dash/backupNow`, {
                    Username: client.username,
                    cometServerID: client.cometServerID,
                    Destination: this.selectedVaultID,
                    TargetID: this.targetId,
                    Source: this.source.id,
                    Options: {
                        "SkipAlreadyRunning": true,
                        "ReduceDiskConcurrency": false,
                        "UseOnDiskIndexes": false,
                        "AllowZeroFilesSuccess": false,
                        "StopAfter": 0,
                        "LimitVaultSpeedBps": 0,
                        "AutoRetentionLevel": 0
                    }
                })
                this.showBackupNowWindow = false;
                showSnack("Successfully dispatched the instruction.")
            } catch (e) {
                console.log(e)
                showSnack('Network Error');
            } finally {
                this.startingBackup = false
            }
        },

        async initBackupNow() {
            try {
                this.startingBackupNow = true;
                const client = this.clients.find(client => client.id === this.clientID)
                const data = await newReq('POST', `dash/initBackupNowButton`, {username: client.username, cometServerID: client.cometServerID})
                this.vaults = data.vaults
                this.targetIds = data.targetIds

                const defaultVault = this.vaults.find(vault => vault.id === this.source.settings.Destination)
                if (defaultVault)
                    this.selectedVaultID = defaultVault.id

                const target = this.targetIds.find(target => target.deviceId === this.deviceID)

                if (!target) {
                    showSnack("ERROR no online targetId found")
                    this.error = true
                } else {
                    this.targetId = target.targetId
                }

                this.showBackupNowWindow = true
            } catch (e) {
                console.log(e)
                showSnack('Network Error');
            } finally {
                this.startingBackupNow = false
            }
        },

        getRefData(dayKey) {
            return {
                deviceID: this.deviceID,
                clientID: this.clientID,
                sourceID: this.source.id,
                dayKey: dayKey,
                ...this.srcRef.jobsByDay[dayKey]
            }
        },
    },
    computed: {
        ...mapGetters(['refJobs', 'cometCodes', "clients"]),

        retryEnabled() {
            return this.source.settings && this.source.settings.EventTriggers && this.source.settings.EventTriggers.OnPCBootIfLastJobMissed
        },

        sourceIcon() {
            return this.source.type === 'file' ? 'mdi-folder' : 'mdi-database';
        },


        daysRef() {
            function getStatusColor(status, cometCodes) {
                try {
                    return cometCodes[status].color || 'grey';
                } catch (e) {
                    console.log(e)
                    return 'grey';
                }

            }

            try {
                return Object.keys(this.srcRef.jobsByDay)
                    .map((dateKey, i) => {
                        let date = this.srcRef != null && typeof this.srcRef.jobsByDay[dateKey] != 'undefined' ? this.srcRef.jobsByDay[dateKey] : null;

                        let obj = {
                            color: date.bestJob != null ? getStatusColor(date.bestJob.status, this.cometCodes) : 'grey',
                            // tooltip: null,
                            index: i,
                            jobsTotal: Array.isArray(date.jobs) ? date.jobs.length : 0,
                            key: dateKey
                            // statusCode: null,
                            // jobObj: date != null ? date.bestJob : null,
                        }


                        return obj
                    }).sort((a,b) => a.dateKey - b.dateKey)
            } catch(e) {
                return []
            }
        }

        // statusIcon() {
        //     try {
        //         let stat = this.devRef.worstJob.status;
        //         if (stat <= 6000) return 'green';
        //         if (stat >= 6000 && stat < 7000) return 'blue';
        //         if ([7001, 7005, 7006].includes(stat)) return 'amber';
        //         if (stat === 7003) return 'purple';
        //         if (stat < 8000) return 'red';
        //         return 'grey';
        //     } catch(e) {
        //         return 'grey';
        //     }
        // }
    },
}
</script>

<style scoped>
    .circle{
        border-radius: 4px;
        width: 30px;
        height: 20px;
    }
</style>