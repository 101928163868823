<template>
    <v-card hover class="pa-1">
<!--Device Heading-->
        <div class="d-flex align-center pl-1">

            <v-tooltip top :disabled="deviceStatusRef">
                <template v-slot:activator="{ on }">
                    <div style="position: relative">
                        <v-icon v-on="on" medium left :color="!deviceStatusRef ? 'red' : null">
                            {{ !deviceStatusRef ? 'mdi-lan-disconnect' : 'mdi-laptop' }}
                        </v-icon>

                        <v-tooltip top v-if="duplicate.length > 1" >
                            <template #activator="ref">
                                <v-icon
                                    style="position: absolute; left: 0; top: 0; transform: translate(-50%, -50%)"
                                    small
                                    color="amber darken-2"
                                    v-on="ref.on"
                                >
                                    mdi-alert
                                </v-icon>
                            </template>
                            <div>
                                <div>
                                    <strong>Duplicate Device For Clients</strong>
                                </div>
                                <div v-for="(client) in duplicate">
                                    {{client.accountName}}
                                </div>
                            </div>

                        </v-tooltip>
                    </div>
                </template>
                Device is offline
            </v-tooltip>
<!--            :class="!deviceStatusRef ? 'red&#45;&#45;text' : null"-->
            <div class="flex-grow-1 subtitle-1">
                {{device.description}}
            </div>

            <v-avatar
                :color="statusIcon"
                size="30"
                class="elevation-3 mr-1"
            >
            </v-avatar>
        </div>

<!--Device Detail-->
        <div class="pl-5">
            <div v-for="source in deviceSources">
                <template v-if="filterItems == null || filterItems.indexOf(source.id) !== -1">
                    <client-list-device-source
                        :client-i-d="clientID"
                        :source="source" :device-i-d="device.id"

                        :src-ref="devRef != null ? devRef.sources[source.id] : null"

                        @dayClicked="$emit('dayClicked', $event)"
                    />
                </template>
            </div>
        </div>
    </v-card>

</template>

<script>
import {mapGetters} from 'vuex';
import ClientListDeviceSource from "@/components/ClientsList/ClientListDeviceSource";
import {getLongDate} from "@/core/codeFunctions";

export default {
    components: {ClientListDeviceSource},
    props: {
        clientID: String,
        device: Object,
        commetServerId: Number,
        filterItems: Array
    },
    computed: {
        ...mapGetters(['refJobs', 'jobs', 'cometCodes', 'deviceStatus', 'activeDevices', 'ref', "duplicateRef"]),

        duplicate() {
            const values = this.duplicateRef.get(this.device.id)
            return values
        },

        devRef() {
            let devRef = typeof this.refJobs[`${this.commetServerId}-${this.device.id}`] == 'undefined' ? null : this.refJobs[`${this.commetServerId}-${this.device.id}`];
            return devRef
        },

        statusIcon() {
            try {
                return this.cometCodes[this.devRef.worstJob.status].color || 'grey';
            } catch(e) {
                return 'grey';
            }
        },

        deviceSources() {
            return (Array.isArray(this.device.sources)
                ? [...this.device.sources].sort((a, b) => a.description > b.description ? 1 : -1)
                : [])
        },

        deviceStatusRef() {
            try {
                return this.activeDevices.includes(this.device.id)
            } catch(e) {
                return null
            }
        }
    }
}
</script>

<style scoped>

</style>