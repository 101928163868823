import { format } from 'date-fns'

function arrSplice(array, spliceOperator) {
    for (let i = 0; i < array.length; i++) {
        let res = spliceOperator(array[i]);
        if (typeof res == 'boolean' && res) {
            array.splice(i, 1);
            return arrSplice(array, spliceOperator);
        }
    }
    return array
}

function arrPopulated(array) {
    return Array.isArray(array) && array.length > 0;
}

function blankString(value) {
    return typeof value === "undefined" || value == null || value === '';
}

function getUUID(){
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c=='x' ? r :(r&0x3|0x8)).toString(16);
    });
    return uuid;
}

function objectifyArr(arr, identifier) {
    return arr.reduce((obj, item) => {
        try {
            obj[item[identifier]] = item
        } catch (e) {
            // eslint-disable-next-line
            console.log(e)
        }
        return obj
    }, {})
}

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function validateCellNo(number) {
    var re = /(^0\d\d\d\d\d\d\d\d\d$)|(^27\d\d\d\d\d\d\d\d\d$)/;
    return re.test(number);
}

function arrPushMerge(arr, vals, compareKey) {
    vals.forEach(val => {
        let bExists = arr.reduce((exists, obj) => {
            if (!exists) {
                if (blankString(compareKey)) {
                    if (val === obj) {return true}
                } else {
                    if (val[compareKey] === obj[compareKey]) {return true}
                }
            }
            return exists
        }, false);
        if (!bExists) {arr.push(val)}
    });
    return arr;
}

function boolToInt(boolVal) {
    if (boolVal) {
        return 1
    } else {
        return 0
    }
}

function intToBool(intVal) {
    if (intVal == 1) {
        return true
    } else {
        return false
    }
}

function getShortDate(date) {
    return format(date, 'yyyy-mm-dd')
}

function sqlToJsDate(sqlDate){
    //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
    let sqlDateArr1 = sqlDate.split("-");
    //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
    let sYear = sqlDateArr1[0];
    let sMonth = (Number(sqlDateArr1[1]) - 1).toString();
    let sqlDateArr2 = sqlDateArr1[2].split(" ");
    //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
    let sDay = sqlDateArr2[0];

    let sHour = '00';
    let sMinute = '00';
    let sSecond = '00';
    let sMillisecond = '00';

    try {
        let sqlDateArr3 = sqlDateArr2[1].split(":");
        //format of sqlDateArr3[] = ['hh','mm','ss.ms']
        sHour = sqlDateArr3[0];
        sMinute = sqlDateArr3[1];
        let sqlDateArr4 = sqlDateArr3[2].split(".");
        //format of sqlDateArr4[] = ['ss','ms']
        try {
            sSecond = sqlDateArr4[0];
        } catch(e) {
            // No Second Milisecond
        }
    } catch(e) {
        // No Date Time
    }

    return new Date(sYear,sMonth,sDay,sHour,sMinute,sSecond);
}

function getInitials(str) {
    try {
        let arrNames = str.split(' ');
        return `${arrNames[0].split('').shift()}${arrNames.pop().split('').shift()}`.toUpperCase();
    } catch(e) {
        return 'XX'
    }
}

function getDisplayUser(user) {
    let displayUser = {
        id: user.id,
        fullName: `${user.name} ${user.surname}`.trim(),
        username: user.username,
        disabled: user.disabled === 1,
        userObj: user
    }

    try {
        let arrNames = displayUser.fullName.split(' ');
        displayUser.initials = `${arrNames[0].split('').shift()}${arrNames.pop().split('').shift()}`.toUpperCase();
    } catch(e) {
        displayUser.initials = 'XX'
    }

    return displayUser
}

function getLongDate(date) {
    return format(date, 'Do MMMM YYYY  h:mm A')
}

const EventEmitter = require('events');
let snackEmitter = new EventEmitter();
function showSnack(message) {
    snackEmitter.emit('showSnack', message)
}

export {
    arrSplice,
    arrPopulated,
    blankString,
    getUUID,
    objectifyArr,
    validateEmail,
    validateCellNo,
    arrPushMerge,
    intToBool,
    boolToInt,
    showSnack,
    getShortDate,
    sqlToJsDate,
    getInitials,
    getDisplayUser,
    getLongDate,
    snackEmitter,
}
