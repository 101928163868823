<template>
    <v-dialog
        :max-width="width != null ? `${width}px` : `500px`"
        :value="value"
        persistent
    >
        <v-card v-if="lazy ? value : false">
            <div class="d-flex flex-wrap: nowrap align-center primary">
                <div class="flex-grow-1 pa-1 pl-2 subtitle-1 white--text">
                    {{title}}
                </div>
                <div class="flex-shrink-1 pr-1">
                    <v-btn
                        color="white"
                        icon
                        small
                        text
                        @click="$emit('input', false)"
                    >
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <div :style="maxHeight != null ? `max-height: ${maxHeight}px` : null" class="pa-0 ma-0 scrollable">
                <slot name="body"></slot>
            </div>
            <v-divider v-if="hasActionSlot"></v-divider>
            <div class="pa-0 ma-0">
                <slot name="action"></slot>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        maxHeight: {
            type: Number,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        title: {
            type: String,
            default: 'No Title Set'
        },
        value: Boolean,
        lazy: {
            default: true,
            type: Boolean
        }
    },
    computed: {
        hasActionSlot() {
            return !!this.$slots['actions']
        }
    }
}
</script>

<style scoped>

</style>