<template>
    <div class="fill-height d-flex flex-column justify-center backdrop">

        <div
            :style="isMobile ? 'width: 400px' : 'width: 500px'"
        >

            <div class="d-flex align-center pt-4 pb-4">

                <v-img
                    class="mx-auto"
                    contain
                    max-width="250px"
                    src="../../assets/logo.svg"
                ></v-img>
            </div>
            <!--            <div class="py-5 text-center">-->
            <!--                <span class="text-h5 grey&#45;&#45;text"><strong>Admin</strong> Console</span>-->
            <!--            </div>-->

            <v-card-text>
                <v-row
                    align="center"
                    no-gutters
                    style="flex-wrap: nowrap;"
                >
                    <v-col
                        class="flex-grow-1 flex-shrink-0"
                    >
                        <v-text-field
                            id="username"
                            ref="username"
                            v-model="username"
                            :disabled="loggingUserIn"
                            dense
                            dark
                            hide-details="auto"
                            color="primary"
                            prepend-icon="mdi-account"
                            placeholder="Username"
                            solo
                            @keydown.enter="password === null || password ===  '' ? $refs.password.focus() : login"
                        ></v-text-field>

                        <v-text-field
                            ref="password"
                            v-model="password"
                            :disabled="loggingUserIn"
                            class="pt-6"
                            dense
                            hide-details="auto"
                            color="primary"
                            prepend-icon="mdi-shield-key"
                            type="password"
                            placeholder="Password"
                            solo
                            @keydown.enter="username === null || username ===  '' ? $refs.username.focus() : login"
                        ></v-text-field>
                    </v-col>

                </v-row>
                <div v-if="loginError != null && loginError !== false" class="pa-2 red--text caption text-center">
                    {{loginError}}
                </div>
                <div class="pt-7 text-right">
                    <v-btn
                        :disabled="loggingUserIn"
                        color="primary"
                        @click="login"
                    >
                        <v-icon left>mdi-account-arrow-right</v-icon>Login
                    </v-btn>
                </div>
            </v-card-text>
        </div>
    </div>
</template>

<script>

import {newReq, initializeRequestAuth} from '@/core/request.js';
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            key: 'Hello',
            username: null,
            password: null,
            loginError: null,
            loggingUserIn: false
        }
    },
    mounted()  {
        this.$nextTick(() => {
            this.$refs.username.focus();
        })
    },
    methods: {
        ...mapActions(['setLogin']),
        login() {
            this.loggingUserIn = true;
            this.loginError = false;
            newReq('post', 'login', {user: this.username, password: this.password}, false, true)
                .then(data => {
                    this.loggingUserIn = false;
                    initializeRequestAuth(data.data);
                    this.setLogin(true);
                })
                .catch(e => {
                    this.loggingUserIn = false;
                    if(typeof e.status != 'undefined') {
                        this.loginError = e.data;
                    } else {
                        this.loginError = 'Server Error';
                    }
                })
        }
    },
    computed: {
        ...mapGetters(['isMobile'])
    }
}
</script>

<style scoped>

</style>