<template>
    <v-app>
        <v-main style="overflow-y: auto; height: 100vh; width: 100vw">

<!--Login-->
            <login v-if="!loggedIn"></login>


            <template v-else>
<!--App Bar-->
                <v-app-bar
                    app
                    class="pl-0"
                    color="black"
                    dark
                    dense
                    src="./assets/banner.jpg"
                >
                    <template v-slot:img="{ props }">
                        <v-img
                            v-bind="props"
                            gradient="to top right, rgba(0,0,0,.5), rgba(0,0,0,.8)"
                        ></v-img>
                    </template>

                    <v-app-bar-nav-icon
                        @click="drawer = !drawer"
                    ></v-app-bar-nav-icon>

                    <v-img
                        contain
                        height="30"
                        max-width="150"
                        src="./assets/logo.svg"
                        style="cursor: pointer"
                    ></v-img>

                    <v-spacer></v-spacer>

                    <template v-if="!isMobile">
                        <div class="d-flex justify-center align-center pr-2" style="width: 450px">
                            <v-text-field
                                v-model="filterStr"
                                label="Search Backup Jobs"
                                dense
                                hide-details
                                solo
                                multiple
                                hide-selected
                                prepend-inner-icon="mdi-magnify"
                            />
                        </div>

                        <div class="d-flex justify-center align-center" style="width: 450px">
                            <v-autocomplete
                                v-model="dashboardFilter.filterCategories"
                                :items="dashboardFilter.categories"
                                label="Filter Job Types"
                                item-text="description"
                                item-value="value"
                                item-color="primary"
                                dense
                                hide-details
                                multiple
                                hide-selected
                                solo
                                prepend-inner-icon="mdi-filter-outline"
                            >

                                <template v-slot:selection="data">
                                    <v-chip
                                        small
                                        :color="data.item.color"
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="dashboardFilter.filterCategories = dashboardFilter.filterCategories.filter(val => val !== data.item.value)"
                                    >
                                        <v-avatar left>
                                            <v-icon>{{data.item.icon}}</v-icon>
                                        </v-avatar>
                                    </v-chip>
                                </template>

                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-avatar>
                                            <v-icon :color="data.item.color">{{data.item.icon}}</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.description"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </template>

                            </v-autocomplete>


                        </div>
                    </template>
                    <v-btn
                        v-else
                        icon
                        color="white"
                        @click="showFilter = true"
                    >
                        <v-icon>mdi-filter-outline</v-icon>
                    </v-btn>

<!--User Menu-->
                    <v-menu
                        v-if="userInfo != null"
                        bottom
                        min-width="200px"
                        offset-y
                        rounded
                    >
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                icon
                            >
                                <v-avatar
                                    color="secondary"
                                    size="35"
                                >
                                    <span class="white--text">{{ userInfo.initials }}</span>
                                </v-avatar>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list-item-content class="justify-center">
                                <div class="mx-auto text-center">
                                    <v-avatar
                                        color="secondary"
                                    >
                                        <span class="white--text headline">{{ userInfo.initials }}</span>
                                    </v-avatar>
                                    <h3 class="pt-3">{{ userInfo.fullName }}</h3>
                                    <p class="caption mt-1">
                                        {{ userInfo.username }}
                                    </p>
<!--                                    <v-divider class="my-3"></v-divider>-->
<!--                                    <v-btn-->
<!--                                        depressed-->
<!--                                        rounded-->
<!--                                        text-->
<!--                                    >-->
<!--                                        Edit Account-->
<!--                                    </v-btn>-->
                                    <v-divider class="my-3"></v-divider>
                                    <v-btn
                                        depressed
                                        rounded
                                        text
                                    >
                                        Logout
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>

                </v-app-bar>
<!--Nav Drawer-->
                <v-navigation-drawer
                    v-if="userInfo != null"
                    v-model="drawer"
                    app
                    bottom
                    temporary
                >
                    <div class="pa-2 primary">
                        <div class="title white--text text-center">
                            {{userInfo.fullName}}
                        </div>
                    </div>

<!--                    <v-switch-->
<!--                        v-model="$vuetify.theme.dark"-->
<!--                        hint="This toggles the global state of the Vuetify theme"-->
<!--                        inset-->
<!--                        label="Vuetify Theme Dark"-->
<!--                        persistent-hint-->
<!--                    ></v-switch>-->

<!--                    <v-divider></v-divider>-->

<!--                    <v-list-->
<!--                        dense-->
<!--                        nav-->
<!--                    >-->
<!--                        <v-list-item link>-->
<!--                            <v-list-item-icon>-->
<!--                                <v-icon>mdi-folder</v-icon>-->
<!--                            </v-list-item-icon>-->
<!--                            <v-list-item-title>My Files</v-list-item-title>-->
<!--                        </v-list-item>-->
<!--                        <v-list-item link>-->
<!--                            <v-list-item-icon>-->
<!--                                <v-icon>mdi-account-multiple</v-icon>-->
<!--                            </v-list-item-icon>-->
<!--                            <v-list-item-title>Shared with me</v-list-item-title>-->
<!--                        </v-list-item>-->
<!--                        <v-list-item link>-->
<!--                            <v-list-item-icon>-->
<!--                                <v-icon>mdi-star</v-icon>-->
<!--                            </v-list-item-icon>-->
<!--                            <v-list-item-title>Starred</v-list-item-title>-->
<!--                        </v-list-item>-->
<!--                    </v-list>-->
                </v-navigation-drawer>
<!--Dashboard-->
                <dashboard/>
            </template>

            <v-snackbar
                v-if="showSnack"
                v-model="showSnack"
                :timeout="snackTimeout"

                :top="true"
                :right="true"
                auto-height
                left
            >
                <div class="d-flex flex-row align-center">
                    <div class="flex-grow-1 flex-shrink-0">
                        {{ snackText }}
                    </div>
                    <div class="flex-grow-0 flex-shrink-1 align-self-center">
                        <v-btn
                            color="secondary"
                            small
                            text
                            @click="showSnack = false"
                        >
                            Close
                        </v-btn>
                    </div>
                </div>

            </v-snackbar>

            <v-bottom-sheet v-model="showFilter" v-if="isMobile">
                <v-sheet
                    class="text-center pa-2"
                >

                    <v-text-field
                        v-model="filterStr"
                        label="Search Backup Jobs"
                        dense
                        class="pb-2"
                        hide-details
                        solo
                        multiple
                        hide-selected
                        prepend-inner-icon="mdi-magnify"
                    />


                    <v-autocomplete
                        v-model="dashboardFilter.filterCategories"
                        :items="dashboardFilter.categories"
                        label="Filter Job Types"
                        item-text="description"
                        item-value="value"
                        item-color="primary"
                        dense
                        hide-details
                        multiple
                        hide-selected
                        solo
                        prepend-inner-icon="mdi-filter-outline"
                    >

                        <template v-slot:selection="data">
                            <v-chip
                                small
                                :color="data.item.color"
                                v-bind="data.attrs"
                                :input-value="data.selected"
                                close
                                @click="data.select"
                                @click:close="dashboardFilter.filterCategories = dashboardFilter.filterCategories.filter(val => val !== data.item.value)"
                            >
                                <v-avatar left>
                                    <v-icon>{{ data.item.icon }}</v-icon>
                                </v-avatar>
                            </v-chip>
                        </template>

                        <template v-slot:item="data">
                            <template>
                                <v-list-item-avatar>
                                    <v-icon :color="data.item.color">{{ data.item.icon }}</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.description"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </template>

                    </v-autocomplete>
                </v-sheet>
            </v-bottom-sheet>
        </v-main>
    </v-app>
</template>

<script>
import Login from './components/Login/Login'
import Dashboard from './components/Dashboard/Dashboard'
import {mapGetters, mapActions} from 'vuex';
import {snackEmitter} from "@/core/codeFunctions";

export default {
    name: 'App',

    components: {
        Login, Dashboard
    },

    data: () => ({
        drawer: false,
        group: null,
        expandedNav: true,
        showSnack: false,
        snackTimeout: 3000,
        snackText: null,
        filterStr: null,
        filterStrUpdate: null,
        showFilter: false
    }),
    watch: {
        filterStr(val) {
            try {
                clearTimeout(this.filterStrUpdate)
            } catch(e) {
                // Error Was Thrown
            } finally {
                setTimeout(() => {
                    this.dashboardFilter.filterStr = val
                }, 500)
            }
        }
    },
    mounted () {
        this.onResize()

        window.addEventListener('resize', this.onResize, { passive: true })
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return

        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    created() {
        this.$vuetify.theme.dark = true

        snackEmitter.on("showSnack", (message) => {
            this.snackText = message;
            this.showSnack = true;
        })
    },
    computed: {
        ...mapGetters(['loggedIn', 'userInfo', 'dashboardFilter', 'isMobile'])
    },
    methods: {
        ...mapActions(['isMobileSet']),

        onResize () {
            this.isMobileSet(window.innerWidth < 600)
        },
    }
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px rgb(30, 30, 30) inset;
    transition: background-color 5000s ease-in-out 0s;
}

html {
    overflow-y: auto
}

::-webkit-scrollbar-track {
    background-color: #ededed;
}
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #F5F5F5;
}
::-webkit-scrollbar-thumb {
    background-color: #d7d7d7;
}

.b1 {border: 1px solid crimson;}
.b2 {border: 1px solid aqua;}
.b3 {border: 1px solid lime;}
.b4 {border: 1px solid fuchsia;}
.backdrop  {
    background-position: top right;
    background-position-x: right;
    background-position-y: top;
    background-color: #1976D2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-size: auto 1000px;
    background-repeat: repeat-y;
    background-image: linear-gradient( 80deg, #242424 calc(100% - 50rem), rgba(25,118,210,0) 100% ), url(./assets/mesh_dark.svg);
}
.scrollable {
    overflow-y: auto;
}
.hide-child {
    display: none;
}
.hide-parent:hover .hide-child {
    display: block;
}

.hover-item:hover{
    background-color: var(--v-hover-base);
}

.ghost {
    opacity: 0.5;
    background: #EEEEEE;
}
.elevate{
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

@keyframes scale-up-center {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

.scale-out-center {
    animation: scale-out-center 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

@keyframes scale-out-center {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 1;
    }
}
</style>
