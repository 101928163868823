const axios = require('axios');
let serverAddress = null;
let sessionID = null;
let token = null;
const EventEmitter = require('events');
let emitter = new EventEmitter();

// Local Functions
setServerAddress();
function setServerAddress() {
    serverAddress = `${this.window.location.protocol}//${this.window.location.hostname}`//`http://orbis.zabackup.co.za`
}

function addRenewEvent(exp) {
    let fireTime = (exp - ((1000 * 60) * 10)) - new Date().getTime();
    if (fireTime >= 1000) {
        setTimeout(() => {
            renewToken()
        }, fireTime)
    } else {
        renewToken()
    }
}

function renewToken(retryTimeOut = 1000) {
    newReq('patch', 'auth/renew')
        .then((data) => {
            token = data;
            addRenewEvent(data.exp)
        })
        .catch(e => {
            if (retryTimeOut <= 32000) {
                setTimeout(() => {
                    renewToken(retryTimeOut * 2)
                }, retryTimeOut)
            } else {
                console.log('Refresh Token Failed')
                emitter.emit('refreshTokenFailed', null)
            }
        })
}

// Public Functions
function newReq(method, url, data = null, includeCredentials = true, fullResponse = false, fullError = false) {
    return new Promise((resolve, reject) => {
        let config = {method: method.toLowerCase(), url: `${serverAddress}/${url}`,}
        if (method.toLowerCase() !== 'get' && data !== null) {config.data = data}
        if (includeCredentials) {config.headers = {"tauth": `${sessionID}.${token.token}`}}
        axios(config)
            .then(response => {
                if (response.status === 200) {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        resolve(fullResponse ? response: response.data)
                    }
                } else {
                    try {
                        response.data = JSON.parse(response.data)
                    } finally {
                        reject(fullResponse ? response: response.data)
                    }
                }
            })
            .catch(e => {
                reject(e)
            })
    })
}

function initialize(newTokenObj) {

    sessionID = newTokenObj.sessionID;
    token = newTokenObj;

    addRenewEvent(token.exp);
}

module.exports = {
    newReq,
    initializeRequestAuth: initialize,
    securityEvents: emitter
}