<template>
    <v-dialog
        v-model="shownForward"
        persistent
        :fullscreen="isMobile"
        :max-width="isMobile ? '500px' : '800px'"
        scrollable
    >
        <v-card :class="isMobile ? 'fill-height' : null" class="d-flex flex-column scrollable">
            <div class="flex-shrink-1 d-flex flex-wrap: nowrap align-center secondary darken-2">
                <div class="flex-grow-1 pa-1 pl-2 subtitle-1 white--text">
                    <v-icon left>mdi-clipboard-list</v-icon>
                    Job Detail
                </div>
                <div class="flex-shrink-1 pr-1">
                    <v-btn
                        color="white"
                        icon
                        small
                        text
                        @click="shownForward = false"
                    >
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="scrollable flex-grow-0 flex-shrink-1">
                <v-progress-linear v-if="loadingJob" indeterminate></v-progress-linear>
                <template v-else>

                    <div class="pa-2">
                        <div class="subtitle-1 pa-2" >
                            {{client.accountName}}
                            <v-icon left right>mdi-arrow-right</v-icon>
                            {{device.description}}
                            <v-icon left right>mdi-arrow-right</v-icon>
                            {{source.description}}
                        </div>

                        <div class="headline pa-2 d-flex align-center">
                            <v-avatar
                                :color="job.statusCategory.color"
                                size="30"
                                class="elevation-3 mr-2"
                            >
                                <v-icon>{{ job.statusCategory.icon }}</v-icon>
                            </v-avatar>
                            <div :class="`${job.statusCategory.color}--text`">
                                {{job.statusCategory.description}}
                            </div>
                        </div>

                        <v-row no-gutters class="pa-1">
<!--Start-->
                            <v-col :cols="!isMobile ? 6 : 12" class="pa-1 d-flex align-center">
                                <v-icon left>mdi-clock-start</v-icon>
                                <div class="lightText--text body-1">Start:</div>
                                <div class="body-1 pl-2">{{format(job.start * 1000, 'do MMM yyyy HH:mm')}}</div>
                            </v-col>
<!--End-->
                            <v-col :cols="!isMobile ? 6 : 12" class="pa-1 d-flex align-center">
                                <v-icon left>mdi-clock-end</v-icon>
                                <div class="lightText--text body-1">End:</div>
                                <div class="body-1 pl-2">{{job.end ? format(job.end * 1000, 'do MMM yyyy HH:mm') : '-'}}</div>
                            </v-col>

<!--Download-->
                            <v-col :cols="!isMobile ? 4 : 12"  class="pa-1 d-flex align-center caption flex-grow-1">
                                <v-icon left>mdi-download</v-icon>
                                <div class="lightText--text body-1">Download:</div>
                                <div class="body-1 pl-2">{{floor((job.downloadSize/1024/1024)*10)/10}} MB</div>
                            </v-col>
<!--Upload-->
                            <v-col :cols="!isMobile ? 4 : 12"  class="pa-1 d-flex align-center caption flex-grow-1">
                                <v-icon left>mdi-upload</v-icon>
                                <div class="lightText--text body-1">Upload:</div>
                                <div class="body-1 pl-2">{{floor((job.uploadSize/1024/1024)*10)/10}} MB</div>
                            </v-col>
<!--Total Size-->
                            <v-col :cols="!isMobile ? 4 : 12"  class="pa-1 d-flex align-center caption flex-grow-1">
                                <v-icon left>mdi-harddisk</v-icon>
                                <div class="lightText--text body-1">Total:</div>
                                <div class="body-1 pl-2">{{floor((job.totalSize/1024/1024)*10)/10}} MB</div>
                            </v-col>

                        </v-row>
                    </div>

                    <div>
                        <div class="subtitle-1 d-flex align-center pa-2 primary--text">
                            <v-icon left color="primary">mdi-clipboard-list</v-icon>
                            Report
                        </div>
                        <v-divider></v-divider>
                        <div style="max-height: 400px" class="scrollable" :class="isMobile ? 'fill-height' : null">
                            <div class="d-flex align-center pa-1" v-for="entry in [...entries].sort((a,b) => a.Severity === 'E' || a.Severity === 'W' ? -1 : 1)" :class="`${entriesRef[entry.Severity].color}--text`">
                                <v-icon left small :color="entriesRef[entry.Severity].color">{{entriesRef[entry.Severity].icon}}</v-icon>
                                <div class="body-2" style="white-space: nowrap">{{format(entry.Time * 1000, 'yyyy-MM-dd HH:mm')}}</div>
                                <div class="caption pl-2">{{entry.Message}}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>

import {mapGetters} from 'vuex';
import {newReq} from '@/core/request.js';
import {format} from 'date-fns';

export default {
    props: {
        shown: Boolean,
        job: Object,
        jobMeta: Object,
    },
    data() {
        return {
            loadingJob: false,
            entries: [],
            entriesRef: {
                W: {description: 'Warning', color: 'amber', icon: 'mdi-alert'},
                E: {description: 'Error', color: 'red', icon: 'mdi-alert-octagon'},
                I: {description: 'Information', color: 'white', icon: 'mdi-information'}
            }
        }
    },
    watch: {
        shown: {
            immediate: true,
            handler(val) {
                if (val)
                    this.loadJobDetail()
            }
        }
    },
    methods: {
        format: format,
        floor: Math.floor,
        loadJobDetail() {
            this.loadingJob = true;
            newReq('post', `dash/job`, {jobID: this.job.id, ...this.jobMeta})
                .then((data) => {
                    this.entries = data
                })
                .catch(e => {
                    console.log(e)
                })
                .finally(() => {
                    this.loadingJob = false
                })
        },
    },
    computed: {
        ...mapGetters(['isMobile', 'clients']),
        shownForward: {
            get() {
                return this.shown
            },
            set(val) {
                this.$emit('shown', val)
            }
        },
        client() {
            return this.clients.filter(client => client.id === this.jobMeta.clientID).pop()
        },
        device() {
            return this.client.devices.filter(device => device.id === this.jobMeta.deviceID).pop()
        },
        source() {
            return this.device.sources.filter(source => source.id === this.jobMeta.sourceID).pop()
        }
    },

}
</script>

<style scoped>

</style>