import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        theme: { dark: true },
        themes: {
            light: {
                primary: '#20a14f',
                secondary: '#00838f',
                hover: '#F5F5F5',
                lightText: '#616161',
            },
            dark: {
                primary: '#20a14f',
                secondary: '#00ACC1',
                hover: '#212121',
                lightText: '#BDBDBD',
            },
        },
    },
});
