import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import {objectifyArr, getDisplayUser, blankString, arrPopulated} from '@/core/codeFunctions';
import { getDayOfYear, getYear } from 'date-fns'
const daysWorthOfJobs = 10;
const cometCodes = {
    "4000": {code: 4000, status: 'JOB_CLASSIFICATION_UNKNOWN', class: 'JobClassification', description: 'JOB_CLASSIFICATION_UNKNOWN'},
    "4001": {code: 4001, status: 'JOB_CLASSIFICATION_BACKUP', class: 'JobClassification', description: 'JOB_CLASSIFICATION_BACKUP'},
    "4002": {code: 4002, status: 'JOB_CLASSIFICATION_RESTORE', class: 'JobClassification', description: 'JOB_CLASSIFICATION_RESTORE'},
    "4003": {code: 4003, status: 'JOB_CLASSIFICATION_RETENTION', class: 'JobClassification: Automatic or manual retention cleaning', description: 'JOB_CLASSIFICATION_RETENTION'},
    "4004": {code: 4004, status: 'JOB_CLASSIFICATION_UNLOCK', class: 'JobClassification: Another process needed exclusive Vault access ( for retention) but the process This task cleans up exclusive', description: 'JOB_CLASSIFICATION_UNLOCK'},
    "4005": {code: 4005, status: 'JOB_CLASSIFICATION_DELETE_CUSTOM', class: 'JobClassification: A specific snapshot has been deleted via the Restore', description: 'JOB_CLASSIFICATION_DELETE_CUSTOM'},
    "4006": {code: 4006, status: 'JOB_CLASSIFICATION_REMEASURE', class: 'JobClassification: Explicitly re-measuring the size of a Vault (right-click > Advanced menu).', description: 'JOB_CLASSIFICATION_REMEASURE'},
    "4007": {code: 4007, status: 'JOB_CLASSIFICATION_UPDATE', class: 'JobClassification: Software update', description: 'JOB_CLASSIFICATION_UPDATE'},
    "4008": {code: 4008, status: 'JOB_CLASSIFICATION_IMPORT', class: 'JobClassification', description: 'JOB_CLASSIFICATION_IMPORT'},
    "4009": {code: 4009, status: 'JOB_CLASSIFICATION_REINDEX', class: 'JobClassification: Repair indexes', description: 'JOB_CLASSIFICATION_REINDEX'},
    "4010": {code: 4010, status: 'JOB_CLASSIFICATION_DEEPVERIFY', class: 'JobClassification', description: 'JOB_CLASSIFICATION_DEEPVERIFY'},
    "4011": {code: 4011, status: 'JOB_CLASSIFICATION_UNINSTALL', class: 'JobClassification: Software uninstall', description: 'JOB_CLASSIFICATION_UNINSTALL'},
    "4999": {code: 4999, status: 'JOB_CLASSIFICATION__MAX', class: 'JobClassification', description: 'JOB_CLASSIFICATION__MAX'},

    // Blue
    "6000": {code: 6000, status: 'JOB_STATUS_RUNNING_INDETERMINATE',        color: 'blue',severity: 1, description: 'JOB_STATUS_RUNNING_INDETERMINATE', class: 'JobStatus: Unused'},
    "6001": {code: 6001, status: 'JOB_STATUS_RUNNING_ACTIVE',               color: 'blue',severity: 2, description: 'JOB_STATUS_RUNNING_ACTIVE', class: 'JobStatus'},
    "6002": {code: 6002, status: 'JOB_STATUS_RUNNING_REVIVED',              color: 'blue',severity: 3, description: 'JOB_STATUS_RUNNING_REVIVED', class: 'JobStatus: A backup job that was marked as stopped or abandoned, but has somehow continued to run'},
    "6999": {code: 6999, status: 'JOB_STATUS_RUNNING__MAX',                 color: 'blue',severity: 4, description: 'JOB_STATUS_RUNNING__MAX', class: 'JobStatus'},

    // Green
    "5000": {code: 5000, status: 'JOB_STATUS_STOP_SUCCESS',                 color: 'green',severity: 5, description: 'JOB_STATUS_STOP_SUCCESS', class: 'JobStatus'},
    "5999": {code: 5999, status: 'JOB_STATUS_STOP_SUCCESS__MAX',            color: 'green',severity: 6, description: 'JOB_STATUS_STOP_SUCCESS__MAX', class: 'JobStatus'},

    // Amber
    "7001": {code: 7001, status: 'JOB_STATUS_FAILED_WARNING',               color: 'amber',severity: 7, description: 'JOB_STATUS_FAILED_WARNING', class: 'JobStatus'},
    "7005": {code: 7005, status: 'JOB_STATUS_FAILED_CANCELLED',             color: 'amber',severity: 8, description: 'JOB_STATUS_FAILED_CANCELLED', class: 'JobStatus'},
    "7006": {code: 7006, status: 'JOB_STATUS_FAILED_SKIPALREADYRUNNING',    color: 'amber',severity: 9, description: 'JOB_STATUS_FAILED_SKIPALREADYRUNNING', class: 'JobStatus'},
    // Purple
    "7003": {code: 7003, status: 'JOB_STATUS_FAILED_QUOTA',                 color: 'purple',severity: 10, description: 'JOB_STATUS_FAILED_QUOTA', class: 'JobStatus'},
    // Red
    "7000": {code: 7000, status: 'JOB_STATUS_FAILED_TIMEOUT',               color: 'red',severity: 11, description: 'JOB_STATUS_FAILED_TIMEOUT', class: 'JobStatus'},
    "7002": {code: 7002, status: 'JOB_STATUS_FAILED_ERROR',                 color: 'red',severity: 12, description: 'JOB_STATUS_FAILED_ERROR', class: 'JobStatus'},
    "7004": {code: 7004, status: 'JOB_STATUS_FAILED_SCHEDULEMISSED',        color: 'red',severity: 13, description: 'JOB_STATUS_FAILED_SCHEDULEMISSED', class: 'JobStatus'},
    "7007": {code: 7007, status: 'JOB_STATUS_FAILED_ABANDONED',             color: 'red',severity: 14, description: 'JOB_STATUS_FAILED_ABANDONED', class: 'JobStatus'},
    "7999": {code: 7999, status: 'JOB_STATUS_FAILED__MAX',                  color: 'red',severity: 15, description: 'JOB_STATUS_FAILED__MAX', class: 'JobStatus'},
}

const jobCategories = [
    {description: 'Running', color: 'blue', value: 'blue', icon: 'mdi-run', statusCodes: [6000, 6001, 6002, 6999]},
    {description: 'Successful', color: 'green', value: 'green', icon: 'mdi-check-circle-outline', statusCodes: [5000, 5999]},
    {description: 'Warning', color: 'amber', value: 'amber', icon: 'mdi-alert-outline', statusCodes: [7001, 7005, 7006]},
    {description: 'Quota', color: 'purple', value: 'purple', icon: 'mdi-minus-circle-outline', statusCodes: [7003]},
    {description: 'Failed', color: 'red', value: 'red', icon: 'mdi-close-circle-outline', statusCodes: [7000, 7002, 7004, 7007, 7999]},
];


export default new Vuex.Store({
    state: {
        isMobile: false,
        loggedIn: false,
        userID: null,
        clientInfo: [],
        users: [],
        cometServers: [],
        companies: [],
        clientSchemas: [],
        clients: [],
        jobs: null,
        refJobs: null,
        dashboardFilter: {
            filterStr: null,
            filterCategories: [],
            categories: jobCategories,
            hardClientFilter: null
        },
        deviceStatus: null,
    },
    mutations: {
        login(state, payload) {
            state.loggedIn = payload;
        },
        appData(state, payload) {
            state.userID = payload.userID;
            state.users = payload.users;

            state.clientInfo = payload.clientInfo;
            state.cometServers = payload.cometServers;
            state.companies = payload.companies;
            state.clientSchemas = payload.clientSchemas;
            state.clients = payload.clients;
            state.jobs = payload.jobs;
            state.refJobs = payload.refJobs;
            state.deviceStatus = payload.deviceStatus
        },
        isMobile(state, payload) {
            state.isMobile = payload
        },
    },
    actions: {
        setLogin(context, payload) {
            context.commit('login', payload)
        },
        setAppData(context, payload) {
            context.commit('appData', payload)
        },
        isMobileSet(context, payload) {
            context.commit('isMobile', payload)
        },
    },
    getters: {
        // Ref Info
        jobCategories() {
            return jobCategories
        },
        isMobile(state) {
            return state.isMobile
        },
        // User Information
        loggedIn(state) {
            return state.loggedIn;
        },
        userInfo(state) {
            return state.userID == null ? null : getDisplayUser(state.users.filter(user => user.id === state.userID).pop())
        },
        users(state) {
            return state.users.map(user => {
                return getDisplayUser(user)
            })
        },
        displayUsersAllRef(state, getters) {
            return objectifyArr(getters.users, 'id')
        },
        displayUsersActive(state, getters) {
            return getters.users.filter(user => !user.disabled)
        },

        // Other Store Information
        deviceStatus(state) {
            return state.deviceStatus
        },
        activeDevices(state) {
            return Object.entries(state.deviceStatus)
                .map(obj => {
                    return obj[1].DeviceID
                })
        },
        clientInfo(state) {
            return state.clientInfo
        },
        cometServers(state) {
            return state.cometServers
        },
        cometServersRef(state) {
            return objectifyArr(state.cometServers, 'id')
        },
        companies(state) {
            return state.companies
        },
        clientSchemas(state) {
            return state.clientSchemas
        },
        clients(state, getters) {
            let clients = state.clients.sort((a, b) => a.accountName > b.accountName ? 1 : -1)
            return clients
        },
        duplicateRef(state, getters) {
            const map = new Map()
            for (const client of getters.clients) {
                for (const device of client.devices) {
                    if (!map.has(device.id))
                        map.set(device.id, [client])
                    else {
                        const clientArr = map.get(device.id)
                        clientArr.push(client)
                    }
                }
            }
            return map
        },
        jobs(state) {
            return state.jobs
        },
        refJobs(state) {
            return state.refJobs
        },
        cometCodes() {
            return cometCodes;
        },
        dashboardFilter(state) {
            return state.dashboardFilter
        },
        filterItems(state, getters) {
            
            if (state.dashboardFilter.hardClientFilter) {
                
                const client = getters.clients.find(client => client.id === state.dashboardFilter.hardClientFilter)
                const res = {}
                res[client.id] = (client.devices || [])
                    .reduce((obj, device) => {
                        obj[device.id] = device.sources.map(src => src.id)
                        return obj
                    }, {})
                return res
            }

            // Flatten The sources to an array
            let flatten = getters.clients
                .reduce((list, client) => {
                    let devicesFlat = client.devices
                        .reduce((list, device) => {
                            let sourcesFlat = device.sources.map(source => {
                                return {srcID: source.id, srcDes: source.description, type: source.type}
                            })
                            if (sourcesFlat.length) {
                                return list.concat(
                                    sourcesFlat.map(source => {
                                        return {
                                            ...source,
                                            devID: device.id,
                                            devDes: device.description
                                        }
                                    })
                                )
                            } else {
                                return list.concat(
                                    {
                                        devID: device.id,
                                        devDes: device.description
                                    }
                                )
                            }
                        }, [])

                    return list.concat(
                        devicesFlat.map(device => {
                            return {
                                ...device,
                                cliID: client.id,
                                cliUsr: client.username,
                                cliDes: client.accountName,
                                emails: client.emails,
                                comSer: client.cometServerID
                            }
                        })
                    )
                }, [])
                .map(obj => {
                    try {
                        obj = {...obj, ...getters.refJobs[`${obj.comSer}-${obj.devID}`].sources[obj.srcID].worstJob}
                    } catch(e) {
                        obj.nojob = true
                    }
                    return obj
                })


            let filterStatus = state.dashboardFilter.filterCategories.reduce((list, cat) => {
                if (cat === 'blue')
                    list = list.concat( [6000, 6001, 6002, 6999])
                if (cat === 'green')
                    list = list.concat([5000, 5999])
                if (cat === 'amber')
                    list = list.concat([7001, 7005, 7006])
                if (cat === 'purple')
                    list = list.concat([7003])
                if (cat === 'red')
                    list = list.concat([7000, 7002, 7004, 7007, 7999])
                return list
            }, [])

            let filterRes = blankString(getters.dashboardFilter.filterStr)
                ? flatten
                    .filter(obj => filterStatus.length === 0 || filterStatus.includes(obj.status))

                    .reduce((obj, item) => {
                        if (typeof obj[item.cliID] == 'undefined')
                            obj[item.cliID] = {};
                        if (typeof obj[item.cliID][item.devID] == 'undefined')
                            obj[item.cliID][item.devID] = [];
                        obj[item.cliID][item.devID].push(item.srcID)
                        return obj
                    }, {})
                //     .reduce((obj, item) => {
                //     if (obj.clients.indexOf(item.cliID) === -1)
                //         obj.clients.push(item.cliID)
                //
                //     if (obj.devices.indexOf(item.devID) === -1)
                //         obj.devices.push(item.devID)
                //
                //     obj.sources.push(item.srcID)
                //     return obj
                // }, {clients: [], devices: [], sources: []})

                : flatten.reduce((list, item) => {
                    if (
                        JSON.stringify(item).toLowerCase().includes(getters.dashboardFilter.filterStr.toLowerCase())
                    ) {
                        list.push(item)
                    }
                    return list
                }, [])
                    .filter(obj => filterStatus.length === 0 || filterStatus.includes(obj.status))
                    .reduce((obj, item) => {
                        if (typeof obj[item.cliID] == 'undefined')
                            obj[item.cliID] = {};
                        if (typeof obj[item.cliID][item.devID] == 'undefined')
                            obj[item.cliID][item.devID] = [];
                        obj[item.cliID][item.devID].push(item.srcID)
                        return obj
                    }, {})
            return filterRes
        }
    }
})
