<template>
    <div class="d-flex fill-height scrollable">

        <div class="fill-height scrollable" style="width: 350px; background: #1e1e1e">
            <div class="body-1" style="position: sticky; top: 0; z-index: 1; background: #1e1e1e">
                <div class="pa-1">
                    <v-text-field
                        prepend-inner-icon="mdi-magnify"
                        placeholder="Search by Client"
                        v-model="clientListFilter"
                        solo
                        flat
                        dense
                        hide-details
                    ></v-text-field>
                </div>
            </div>
            <v-list
            >
                <v-list-item-group
                    v-model="$store.state.dashboardFilter.hardClientFilter"
                    color="primary"
                >
                    <v-list-item
                        v-for="client in listClients"
                        :key="client.id"
                        :value="client.id"
                    >
                        <v-list-item-content>
                            <v-list-item-title>{{client.accountName}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </div>

        <div class="scrollable py-2 flex-grow-1" style="margin-top: 56px; height: calc(100% - 56px)">
            <v-data-iterator
                :items="filteredClients"
                :items-per-page.sync="itemsPerPage"
                :page.sync="page"
                :hide-default-footer="true"
            >
                <!--            :sort-by="sortBy.toLowerCase()"-->
                <!--            :sort-desc="sortDesc"-->

                <template v-slot:default="props">
                    <div class="d-flex flex-column align-center">
                        <template v-for="client in props.items">
                            <template v-if="filterItems == null || typeof filterItems[client.id] !== 'undefined'">
                                <client-list-client
                                    style="max-width: 1200px; width: 100%"
                                    :key="client.id"
                                    :filter-items="filterItems == null ? null : filterItems[client.id]"
                                    :client="client"
                                    @dayClicked="dayClicked($event)"
                                />
                            </template>
                        </template>
                    </div>
                </template>

                <template #footer="{pagination}">
                    <div style="position: fixed; right: 0; top: 51px;" class="pa-2 d-flex align-center">
                        <div style="line-height: 1" class="mx-2">
                            Page: {{pagination.page}} of {{pagination.pageCount - 1}}
                        </div>
                        <v-btn
                            :disabled="page <= 1"
                            @click="--page"
                            icon
                            class="deep-orange mx-1"
                        >
                            <v-icon color="white">
                                mdi-chevron-left
                            </v-icon>
                        </v-btn>
                        <v-btn
                            :disabled="!(pagination.page < pagination.pageCount)"
                            @click="++page"
                            icon
                            class="deep-orange mx-1"
                        >
                            <v-icon color="white">
                                mdi-chevron-right
                            </v-icon>
                        </v-btn>

                    </div>
                </template>
            </v-data-iterator>
        </div>




<!--        <template v-for="(client, i) in clients">-->
<!--            <template v-if="filterItems == null || typeof filterItems[client.id] !== 'undefined'">-->
<!--                <client-list-client-->
<!--                    :filter-items="filterItems == null ? null : filterItems[client.id]"-->
<!--                    :key="i"-->
<!--                    :client="client"-->
<!--                    @dayClicked="dayClicked($event)"-->
<!--                />-->
<!--            </template>-->
<!--        </template>-->

        <!--Day Menu-->
        <template v-if="!isMobile">
            <v-menu
                v-model="showDayMenu"
                :position-x="dayMenuX"
                :position-y="dayMenyY"
                absolute
                offset-y
                :transition="null"
                :close-on-content-click="false"
                nudge-bottom="30"
                nudge-left="200"
            >
                <v-card class="lighten-5" width="400">
                    <day-menu v-if="showDayMenu" ref="dayMenu" @hideMenu="showDayMenu = false"/>
                </v-card>
            </v-menu>
        </template>
        <template v-else>
            <v-bottom-sheet v-model="showDayMenu">
                <v-sheet
                    class="text-center pa-2"
                >
                    <day-menu v-if="showDayMenu" ref="dayMenu" @hideMenu="showDayMenu = false"/>
                </v-sheet>
            </v-bottom-sheet>
        </template>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ClientListDevice from "@/components/ClientsList/ClientListDevice";
import ClientListClient from "@/components/ClientsList/ClientListClient";
import DayMenu from "@/components/ClientsList/Components/DayMenu";

export default {
    components: {DayMenu, ClientListClient, ClientListDevice},
    data() {
        return {
            droppedClients: [],
            showDayMenu: false,
            dayMenuX: 0,
            dayMenyY: 0,
            itemsPerPage: 10,
            page: 1,
            clientListFilter: '',
            selectedClient: null
        }
    },
    watch: {
        filterItems: {
            deep: true,
            handler() {
                this.page = 1
            }
        }
    },
    methods: {
        dayClicked(payload) {
            payload.event.preventDefault()
            this.showDayMenu = false
            this.$nextTick(() => {
                this.dayMenuX = payload.event.clientX
                this.dayMenyY = payload.event.clientY
            })
            setTimeout(() => {
                this.$nextTick(() => {
                    this.showDayMenu = true
                    this.$nextTick(() => {this.$refs.dayMenu.initDayMenu(payload.data)})
                })
            }, 1)
        },
    },
    computed: {
        ...mapGetters([
            "clients",
            "filterItems",
            "isMobile"
        ]),

        listClients() {
            return !this.clientListFilter ? this.clients : this.clients.filter(client => client.accountName.toLowerCase().includes(this.clientListFilter.toLowerCase()))
        },

        filteredClients() {
            // filterItems == null || typeof filterItems[client.id] !== 'undefined'
            return !this.filterItems ? this.clients : this.clients.filter(client => typeof this.filterItems[client.id] !== 'undefined')
        }
    }
}
</script>

<style scoped>

</style>