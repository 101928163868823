<template>
    <div class="fill-height scrollable">
        <v-progress-linear v-if="startingUp" indeterminate></v-progress-linear>
        <client-list v-else class="flex-grow-1 scrollable" />
    </div>
</template>

<script>

import {mapGetters, mapActions} from 'vuex';
import {newReq} from '@/core/request.js';
import ClientList from '../ClientsList/ClientsList'

export default {
    components: {
        ClientList
    },
    data() {
        return {
            startingUp: false,
            showOffDaysManager: false,
            showAdjustmentsManager: false,
            showUsersManager: false
        }
    },
    mounted() {
        this.startup()
    },
    methods: {
        startup() {
            this.startingUp = true;
            newReq('get', `dash/startup`)
                .then((data) => {
                    this.setAppData(data);
                    this.startingUp = false;
                })
                .catch(e => {
                    console.log(e)
                })
        },
        ...mapActions(['setAppData'])
    },
    computed: {
        ...mapGetters([
            "loggedIn",
            "userInfo",
            "users",
            "displayUsersAllRef",
            "displayUsersActive",
            "clientInfo",
            "cometServers",
            "companies",
            "clientSchemas",
            "clients",
            "jobs",
            "filterItems"
        ]),
    }
}
</script>

<style scoped>

</style>