<template>
    <div>

        <div class="px-4 pt-2 pb-0 text-center" v-if="loadingData">
            <v-progress-circular
                :size="20"
                :width="2"
                color="primary"
                indeterminate
            />
            <div class="overline">
                Loading Job Data
            </div>
        </div>

        <div v-else>
            <template v-for="job in dayJobsRefDisplay">
                <div class="d-flex align-center pa-1 hover-item" style="cursor: pointer" v-ripple @click="showJobDetailRun(job)">
                    <v-avatar
                        :color="job.statusCategory.color"
                        size="30"
                        class="elevation-3 ml-1"
                    >
                        <v-icon>{{ job.statusCategory.icon }}</v-icon>
                    </v-avatar>

                    <div class="px-2 flex-grow-1">
<!--Start & End-->
                        <div class="d-flex justify-space-around">
<!--Start-->
                            <div class="d-flex align-center caption flex-grow-1">
                                <v-icon small left>mdi-clock-start</v-icon>
                                {{format(job.start * 1000, 'do MMM yyyy HH:mm')}}
                            </div>
<!--End-->
                            <div class="d-flex align-center caption flex-grow-1">
                                <v-icon small left>mdi-clock-end</v-icon>
                                {{job.end ? format(job.end * 1000, 'do MMM yyyy HH:mm') : '-'}}
                            </div>
                        </div>
<!--Size & Files-->
                        <div class="d-flex">
                            <!--Download-->
                            <div class="d-flex align-center caption flex-grow-1">
                                <v-icon small left>mdi-download</v-icon>
                                {{floor((job.downloadSize/1024/1024)*10)/10}} MB
                            </div>
                            <!--Upload-->
                            <div class="d-flex align-center caption flex-grow-1">
                                <v-icon small left>mdi-upload</v-icon>
                                {{floor((job.uploadSize/1024/1024)*10)/10}} MB
                            </div>
                            <!--Total Size-->
                            <div class="d-flex align-center caption flex-grow-1">
                                <v-icon small left>mdi-harddisk</v-icon>
                                {{floor(job.totalSize/1024/1024)}} MB
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <job-detail v-if="showJobDetail" @shown="showJobDetail = $event" :shown="showJobDetail" :job="jobDetailJob" :job-meta="{clientID: clientRef.id, comServer: clientRef.fkCometServer, deviceID: dayData.deviceID, sourceID: dayData.sourceID}"/>
    </div>

</template>

<script>

import {mapGetters} from 'vuex';
import { format, parseISO } from 'date-fns';
import JobDetail from './JobDetail';

export default {
    components: {
        JobDetail
    },
    data() {
        return {
            loadingData: true,
            showJobDetail: false,
            jobDetailJob: null,
            dayData: null,
            clientRef: null,
            dayJobsRef: null,
        }
    },
    watch: {
        showJobDetail(val) {
            if (!val)
                this.$emit('hideMenu')
        }
    },
    methods: {
        parseISO: parseISO,
        format: format,
        floor: Math.floor,
        initDayMenu(dayData) {
            this.dayData = dayData
            this.$nextTick(() => {
                this.getClientInfo(dayData.clientID);
                this.getDayJobsRef(dayData.deviceID)
                this.loadingData = false;
            })
        },
        getClientInfo(clientID) {
            this.clientRef = this.clientInfo.filter(client => client.id === clientID).pop()
        },
        getDayJobsRef(deviceID) {
            this.dayJobsRef = this.jobs[deviceID]
                .filter(job => this.dayData.jobs.includes(job.id))
        },
        showJobDetailRun(job) {
            this.jobDetailJob = job;
            this.showJobDetail = true
        }
    },
    computed: {
        ...mapGetters(['clientInfo', 'jobs', 'jobCategories']),
        dayJobsRefDisplay() {
            return this.dayJobsRef
                .map(job => {
                    let obj = {
                        ...job
                    }

                    obj.statusCategory = this.jobCategories.filter(jCategory => jCategory.statusCodes.includes(job.status)).pop()
                    return obj
                })
        }
    }
}
</script>

<style scoped>

</style>